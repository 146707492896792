@tailwind base;
@tailwind utilities;
@tailwind components;

.orange { color: #f17a00; }

.inventory-wrap {
  height: 220px;
  border: 10px solid white;
  border-radius: 5px;
  /*background-position: right 50% bottom 48px;*/
  background-size: contain;
  background-repeat: no-repeat;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-content {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown .dropdown-content a:hover {
  background: white;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0 % { -webkit-transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg);}
}

@keyframes spinner {
  0 % {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.photo-panel {
  margin-top: 30px;
}

.auction-start {
  line-height: 1.2em;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
}

button:hover { opacity: 0.8; }